// Chatbot styles
.docking-container {
    transition: width 0.5s;
    height: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    // padding: 16px 12px;
    border-left: 1px solid #c17ecc;
}

.buttons-container {
    display: flex;
    align-items: center;
    justify-content: start;
    border-top: 1px solid #c17ecc;

}

.react-chatbot-kit-chat-bot-message {
    background-color: #776d7e;
}

.react-chatbot-kit-chat-btn-send {
    background-color: #78246c;
}

.react-chatbot-kit-chat-bot-message-arrow {
    border-right: 8px solid #776d7e !important;
}

.react-chatbot-kit-user-avatar-container {
    background-color: #c17ecc;
}

.react-chatbot-kit-chat-input:focus-visible {
    outline-width: 0px;
}

.react-chatbot-kit-chat-message-container {
    overflow: auto;
}

.react-chatbot-kit-chat-container {
    width: 100%;
}

.react-chatbot-kit-chat-bot-message {
    margin-left: 0px;
    width: auto;
}

.react-chatbot-kit-user-chat-message {
    background-color: #d8d8d8;
}

.react-chatbot-kit-user-chat-message-arrow {
    border-left: 8px solid #d8d8d8;
}

// .react-chatbot-kit-chat-inner-container,
// .react-chatbot-kit-chat-message-container {
//   height: calc(100% - 116px);
// }
.docking-container>div:first-child {
    height: calc(100% - 54px);
}

.docking-container>div>div {
    height: 100%;
}

.react-chatbot-kit-chat-container {
    height: 100%;
}

.react-chatbot-kit-chat-inner-container {
    height: calc(100% - 41px);
    border-radius: 0px;
}

.react-chatbot-kit-chat-message-container {
    height: calc(100% - 50px);
}

.react-chatbot-kit-chat-input {
    border-bottom-left-radius: 0px;
    border-top: 1px solid #c17ecc;
}

.react-chatbot-kit-chat-btn-send {
    border-bottom-right-radius: 0px;
}

.react-chatbot-kit-chat-btn-send-icon {
    width: 20px;
}