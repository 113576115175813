.bot-header-wrapper {
    display: flex;
    padding: 8px;
    background: rgb(114, 53, 161);
    color: rgb(255, 255, 255);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    align-items: center;
}

.bot-side-header {
    background: rgb(193 126 204);
    color: #fff;
    padding: 8px;
    /* border-top-left-radius: 4px; */
    /* border-top-right-radius: 4px; */
    align-items: center;
}

.bot-header-title {
    font-weight: 500;
    color: #fff;
}